/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { BsDot } from 'react-icons/bs';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import JobCreateStep1 from 'src/components/Job/JobCreateStep1';
import JobCreateStep2 from 'src/components/Job/JobCreateStep2';
import HeaderContentBlock from 'src/components/Common/HeaderContentBlock';
import SortFilterContentModal from 'src/components/Common/SortFilterContentModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH } from 'src/constants/path';
import JobSortFilter from 'src/components/Job/JobSortFilter';
import { createJob, jobInEmployeeList, jobList } from 'src/services/job';
import westImg from 'src/assets/img/westImg.png';
import fsImg from 'src/assets/img/fsImg.png';
import logImg from 'src/assets/img/logImg.png';
import { defaultJobCreateData, getRemovetzTime, utcConverter } from 'src/utils/function';
import { Tooltip } from 'flowbite-react';
import { toaster } from 'src/utils/toaster';
import { breakPoints, general, jobStatus } from 'src/constants/general';
import { TiFlash } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { jobSortFilterData } from 'src/redux/reducers/jobFilterSlice';
import { jobCreateData } from 'src/redux/reducers/jobCreateSlice';
import { departmentDataAsync, requestorDataAsync, assignorDataAsync } from 'src/redux/reducers/getCommonApiSlice';
import { FaCircleExclamation } from 'react-icons/fa6';
import { jobViewResponsiveData } from 'src/redux/reducers/jobViewResponsiveSlice';
import ResponsiveHeaderContentBlock from 'src/components/Responsive/ResponsiveHeaderContentBlock';
import ResponsiveJobListAccordion from 'src/components/Responsive/job/ResponsiveJobListAccordion';
import { useDeviceDetect } from 'src/hooks/useDeviceDetect';
import { Paginator } from 'primereact/paginator';

const Job = () => {
    const { isMobile }: any = useDeviceDetect();
    const jobViewResponsiveGetData = useSelector((state: RootState) => state.jobViewResponsive);
    const allListData = useSelector((state: RootState) => state.getCommonApi);
    const dispatch = useDispatch();
    const filterSearch = useSelector((state: RootState) => state.jobFilter);
    const [sortModal, setSortModal] = useState(false);
    const [jobStep, setJobStep] = useState<any>(0);
    const [searchInput, setSearchInput] = useState(filterSearch.searchInput);
    const [filterApply, setFilterApply] = useState(filterSearch.filterApply);
    const [rows, setRows] = useState(filterSearch.rows);
    const [page, setPage] = useState(filterSearch.page);
    const [first, setFirst] = useState(filterSearch.page * filterSearch.rows);
    const [totalJobRecords, setTotalJobRecords] = useState(0);
    const [jobCompanyList, setJobCompanyList] = useState([]);
    const [jobListData, setJobListData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [formateStartDate, setFormateStartDate] = useState('Start Date');
    const [endDate, setEndDate] = useState(null);
    const [formateEndDate, setFormateEndDate] = useState('End Date');
    const [selectEmployee, setSelectEmployee] = useState([]);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [openAcoordionResponsive, setOpenAcoordionResponsive] = useState<any>(null);
    const controller = new AbortController();
    const [selectedValue, setSelectedValue] = useState({
        value: filterSearch.filterSelectedValue,
        label: filterSearch.filterSelectedLabel,
    });
    const [sortBy, setSortBy] = useState({
        date: { name: filterSearch.sortByDateName, value: filterSearch.sortByDateValue },
        jobType: { name: filterSearch.sortByJobTypeName, id: filterSearch.sortByJobTypeId },
        company: { name: filterSearch.sortByCompanyName, id: filterSearch.sortByCompanyId },
    });
    const [step1Data, setStep1Data] = useState({
        asapStatus: false,
        mandatoryStatus: false,
        companyType: {
            name: '',
            id: 0,
        },
        jobType: { name: '', id: 0 },
        jobConfirmation: '1000-',
        workers: '1',
        requestor: '',
        assignor: '',
        department: '',
        location: '',
        startDate: '',
        endDate: '',
        requestorName: '',
        assignorName: '',
        departmentName: '',
        selectedDate: [],
        startOfPrevWeek: '',
        endOfNextWeek: '',
    });
    const [filterBy, setFilterBy] = useState({
        location: filterSearch.location,
        employee: filterSearch.employee,
        requestor: filterSearch.requestor,
        department: filterSearch.department,
        startDate: filterSearch.startDate,
        endDate: filterSearch.endDate,
    });
    const [listData, setListData] = useState<{
        jobList: any[];
        companyList: any[];
        locationList: any[];
        departmentList: any[];
        requestorList: any[];
        employeeList: any[];
        assignorList: any[];
    }>({
        jobList: [],
        companyList: [],
        locationList: [],
        departmentList: [],
        requestorList: [],
        employeeList: [],
        assignorList: [],
    });
    const navigate = useNavigate();
    const Location = useLocation();
    const dateProps = {
        startDate,
        formateStartDate,
        endDate,
        formateEndDate,
        setStartDate,
        setFormateStartDate,
        setEndDate,
        setFormateEndDate,
    };
    useEffect(() => {
        if (searchInput && filterApply) {
            getJobList(
                `?page=${page + 1}&limit=${rows}&status=${selectedValue.value}&search=${searchInput}&company=${sortBy.company.id}&sortBy=${sortBy.date.value}&jobType=${sortBy.jobType.id}&location=${
                    filterBy.location
                }&employee=${filterBy.employee}&requestor=${filterBy.requestor}&department=${filterBy.department}&startDate=${filterBy.startDate}&endDate=${filterBy.endDate}`,
                controller.signal,
            );
        } else if (searchInput) {
            getJobList(`?page=${page + 1}&limit=${rows}&status=${selectedValue.value}&search=${searchInput}`, controller.signal);
        } else if (filterApply) {
            getJobList(
                `?page=${page + 1}&limit=${rows}&status=${selectedValue.value}&company=${sortBy.company.id}&sortBy=${sortBy.date.value}&jobType=${sortBy.jobType.id}&location=${
                    filterBy.location
                }&employee=${filterBy.employee}&requestor=${filterBy.requestor}&department=${filterBy.department}&startDate=${filterBy.startDate}&endDate=${filterBy.endDate}`,
                controller.signal,
            );
        } else {
            getJobList(`?page=${page + 1}&limit=${rows}&status=${selectedValue.value}`, controller.signal);
        }
        return () => controller.abort();
    }, [page, rows, searchInput, filterApply]);

    useEffect(() => {
        getJobCompanyList();
    }, [allListData?.companyData, allListData?.jobTypeData, allListData?.locationData, allListData?.departmentData, allListData?.requestorData, allListData?.assignorData]);
    useEffect(() => {
        getJobEmployeeList();
    }, []);

    useEffect(() => {
        const payload = {
            searchInput: searchInput,
            filterApply: filterApply,
            page: page,
            rows: rows,
            sortByDateName: sortBy.date.name,
            sortByDateValue: sortBy.date.value,
            sortByJobTypeName: sortBy.jobType.name,
            sortByJobTypeId: sortBy.jobType.id,
            sortByCompanyName: sortBy.company.name,
            sortByCompanyId: sortBy.company.id,
            location: filterBy.location,
            employee: filterBy.employee,
            requestor: filterBy.requestor,
            department: filterBy.department,
            startDate: filterBy.startDate,
            endDate: filterBy.endDate,
            filterSelectedValue: selectedValue.value,
            filterSelectedLabel: selectedValue.label,
        };
        dispatch(jobSortFilterData(payload));
        setFirst(page * rows);
    }, [page, rows, searchInput, filterApply]);

    useEffect(() => {
        if (filterSearch.startDate) {
            const preStart: any = makePreDateFill(filterSearch.startDate);
            setStartDate(preStart);
        }
        if (filterSearch.endDate) {
            const preEnd: any = makePreDateFill(filterSearch.endDate);
            setEndDate(preEnd);
        }
    }, [filterSearch.startDate, filterSearch.endDate]);

    const makePreDateFill = (inputDateString: any) => {
        const date = new Date(inputDateString);
        return date;
    };

    const getJobCompanyList = async () => {
        setListData((prevData) => ({
            ...prevData,
            companyList: allListData?.companyData,
            jobList: allListData?.jobTypeData,
            locationList: allListData?.locationData,
            departmentList: allListData?.departmentData,
            requestorList: allListData?.requestorData,
            assignorList: allListData?.assignorData, //add assignerData
        }));
        if (!Location?.state?.filterSearch || filterSearch.filterApply === 0) {
            setSortBy((prevData) => ({
                ...prevData,
                date: { name: 'All', value: '' },
                jobType: {
                    name: 'All',
                    id: '',
                },
                company: {
                    name: 'All',
                    id: '',
                },
            }));
            setSelectedValue({ value: general.INPROGRESS, label: 'In Progress' });
            setFilterBy((prevData) => ({
                ...prevData,
                location: '',
                employee: '',
                requestor: '',
                department: '',
            }));
        }
        setStep1Data((prevData) => ({
            ...prevData,
            location: allListData?.locationData[0]?.id,
            requestor: allListData?.requestorData[0]?.id,
            requestorName: allListData?.requestorData[0]?.name,
            assignor: allListData?.assignorData[0]?.id,
            assignorName: allListData?.assignorData[0]?.name,
            department: allListData?.departmentData[0]?.id,
            departmentName: allListData?.departmentData[0]?.name,
            jobType: {
                name: allListData?.jobTypeData[0]?.name,
                id: allListData?.jobTypeData[0]?.id,
            },
            companyType: {
                name: allListData?.companyData[0]?.name,
                id: allListData?.companyData[0]?.id,
            },
        }));
    };
    const getJobEmployeeList = async () => {
        const employee = await jobInEmployeeList();
        setListData((prevData) => ({
            ...prevData,
            employeeList: employee.data.data,
        }));
    };

    const getJobList = async (url: string, signal?: any) => {
        const res = await jobList(url, signal);
        setJobListData(res?.data?.data?.jobs);
        setTotalJobRecords(res?.data?.data?.total);
        setJobCompanyList(res?.data?.data?.companyList);
        dispatch(jobViewResponsiveData({ responsiveJobCompanyList: res?.data?.data?.companyList }));
        dispatch(jobViewResponsiveData({ responsiveCurrentJobStep: 0 }));
        setOpenAcoordionResponsive(null);
    };

    const onRowClick = async (event: any) => {
        navigate(`${PATH.JOBDETAILINFO}`, { state: { id: event.data.id } });
    };

    const handleSearch = (e: any) => {
        setPage(0);
        setSearchInput(e.target.value);
    };

    const handleFilterClear = () => {
        setPage(0);
        setSortModal(false);
        setFilterApply(0);
        setSortBy((prevData) => ({
            ...prevData,
            date: { name: 'All', value: '' },
            jobType: {
                name: 'All',
                id: '',
            },
            company: {
                name: 'All',
                id: '',
            },
        }));
        setSelectedValue({ value: general.INPROGRESS, label: 'In Progress' });
        setFilterBy((prevData) => ({
            ...prevData,
            location: '',
            employee: '',
            requestor: '',
            department: '',
            startDate: '',
            endDate: '',
        }));
        setFormateStartDate('Start Date');
        setStartDate(null);
        setEndDate(null);
        setFormateEndDate('End Date');
    };

    const handleFilterApply = () => {
        setPage(0);
        setFilterApply(filterApply + 1);
        setSortModal(false);
    };
    const getRequestor = async (type = '') => {
        if (type === 'close' || (step1Data.requestor === null && step1Data.department === null && step1Data.assignor === null)) {
            dispatch(departmentDataAsync());
            dispatch(requestorDataAsync());
            dispatch(assignorDataAsync());
        } else if (step1Data.requestor === null) {
            dispatch(requestorDataAsync());
        } else if (step1Data.assignor === null) {
            dispatch(assignorDataAsync());
        } else if (step1Data.department === null) {
            dispatch(departmentDataAsync());
        }
        setFilterBy((prevData) => ({
            ...prevData,
            requestor: '',
            department: '',
        }));
        setStep1Data((prevData) => ({
            ...prevData,
            location: listData.locationList[0]?.id,
            jobType: {
                name: listData?.jobList[0]?.name,
                id: listData?.jobList[0]?.id,
            },
            companyType: {
                name: listData?.companyList[0]?.name,
                id: listData?.companyList[0]?.id,
            },
            jobConfirmation: '1000-',
            workers: '1',
            startDate: '',
            endDate: '',
            selectedDate: [],
            startOfPrevWeek: '',
            endOfNextWeek: '',
            asapStatus: false,
            mandatoryStatus: false,
        }));
        setSelectEmployee([]);
        setBtnDisabled(false);
    };
    const handleClose = () => {
        setJobStep(0);
        getRequestor('close');
        dispatch(jobViewResponsiveData({ responsiveCurrentJobStep: 0 }));
    };
    const getJobCreate = async () => {
        setBtnDisabled(true);
        const payload = {
            companyId: Number(step1Data.companyType.id),
            jobTypeId: Number(step1Data.jobType.id),
            jobConfirmation: step1Data.jobConfirmation,
            workers: Number(step1Data.workers),
            requestorId: step1Data.requestor === null ? undefined : Number(step1Data.requestor),
            requestorName: step1Data.requestorName,
            assignorId: step1Data.assignor === null ? undefined : Number(step1Data.assignor),
            assignorName: step1Data.assignorName,
            departmentId: step1Data.department === null ? undefined : Number(step1Data.department),
            departmentName: step1Data.departmentName,
            locationId: Number(step1Data.location),
            startDate: utcConverter(step1Data.startDate, 'YYYY-MM-DD HH:mm:ss'),
            endDate: step1Data.endDate ? utcConverter(step1Data.endDate, 'YYYY-MM-DD HH:mm:ss') : null,
            employees: selectEmployee,
            isAsap: step1Data.asapStatus === true ? 1 : 0,
            isMandatory: step1Data.mandatoryStatus === true ? 1 : 0,
        };
        const jobCreate = await createJob(payload);
        if (jobCreate.status === 200) {
            toaster('success', jobCreate.data.message);
            setJobStep(0);
            getRequestor();
            if (page === 0) {
                getJobList(`?page=${page + 1}&limit=${rows}&status=${selectedValue.value}`);
            } else {
                setPage(0);
                setFirst(0);
            }
        } else {
            toaster('error', jobCreate.data.message);
        }
    };
    const isBack = () => {
        setJobStep(1);
        dispatch(jobViewResponsiveData({ responsiveCurrentJobStep: 2 }));
    };
    const gotoStep1 = () => {
        setJobStep(1);
        dispatch(jobCreateData(defaultJobCreateData));
        dispatch(jobViewResponsiveData({ responsiveCurrentJobStep: 1 }));
    };

    const onPageChange = (event: any) => {
        setFirst(event.first);
        setRows(event.rows);
        setPage(event.page);
    };

    const Getcomp = (row: any) => (
        <div className="flex items-center gap-1">
            <img className="w-6 h-6 rounded-full" src={row.companyName === 'West' ? westImg : row.companyName === 'Log' ? logImg : fsImg} alt="img" />
            <div className="text-black text-xs font-medium font-['Satoshi Variable'] uppercase">{row.companyName}</div>
        </div>
    );

    const Getjobtype = (row: any) => (
        <div className="flex items-center">
            <div className="flex items-center gap-0 relative">
                {row.isAsap ? (
                    <span className="w-5 h-5 rounded-full bg-red-50 text-red500 flex justify-center items-center mr-1 absolute left-[-25px]">
                        <TiFlash />
                    </span>
                ) : (
                    ''
                )}
                {row.isMandatory ? (
                    <span className={`iconresponsive w-5 h-5 rounded-full bg-white text-blue-800 flex justify-center items-center mr-1 absolute ${row.isAsap ? 'left-[-50px]' : 'left-[-25px]'}`}>
                        <FaCircleExclamation size={30} />
                    </span>
                ) : (
                    ''
                )}
            </div>
            <div className="text-black text-xs font-medium font-['Satoshi Variable'] uppercase">{row.jobType}</div>
        </div>
    );

    const Getassignemply = (row: any) => {
        const employee = () => (
            <div>
                {row.employeeList.map((data: any, index: number) => (
                    <p className="truncate" key={index}>{`${data.name} ${data.surname}`}</p>
                ))}
            </div>
        );

        return row?.employeeList?.length === 1 ? (
            <p className="truncate">{`${row.employeeList[0].name} ${row.employeeList[0].surname}`}</p>
        ) : (
            <div className="flex">
                <p className="truncate me-[6px]">{`${row.employeeList[0].name} ${row.employeeList[0].surname}..`}</p>
                <Tooltip className="bg-black text-white" content={<>{employee()}</>}>
                    <div className="w-4 h-4 px-2 pt-0.5 pb-1 bg-indigo-500 rounded-2xl flex-col justify-center items-center gap-2 inline-flex">
                        <div className="text-white text-xs font-medium font-['Satoshi Variable']">{row?.employeeList?.length}</div>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const GetStartDate = (row: any) => getRemovetzTime(row.startDate, 'MMM. DD, YY');

    const GetEndDate = (row: any) => {
        if (row.endDate) {
            return getRemovetzTime(row.endDate, 'MMM. DD, YY');
        } else {
            return (
                <div className="">
                    <p className="px-5">-</p>
                </div>
            );
        }
    };

    const Getstatus = (row: any) => {
        const statusColors: any = {
            [jobStatus.complete]: 'bg-Green20 text-Green80',
            [jobStatus.cancel]: 'bg-purpleLight text-purple80',
            [jobStatus.fail]: 'bg-red20 text-red500',
            [jobStatus.inprogress]: 'bg-yellow20 text-yellow80',
            [jobStatus.invoiced]: 'bg-secondary20 text-secondary',
        };

        const isColor = statusColors[row.status] || '';
        return (
            <div className={`h-6 pe-2 min-w-24 py-1 ${isColor} rounded-lg justify-center items-center inline-flex`}>
                <div>
                    <BsDot size={18} />
                </div>
                <div className={`text-xs font-medium`}>{row.status}</div>
            </div>
        );
    };

    const employeeResponsive = (row: any) => (
        <div>
            {row.employeeList.map((data: any, index: number) => (
                <p className="truncate" key={index}>{`${data.name} ${data.surname}`}</p>
            ))}
        </div>
    );

    return (
        <div>
            <ResponsiveHeaderContentBlock
                sortOnClick={() => setSortModal(true)}
                closeOnClick={() => handleClose()}
                addNewOnClick={() => gotoStep1()}
                isDisabled={jobViewResponsiveGetData?.responsiveCurrentJobStep === 0 ? false : true}
                searchOnchange={handleSearch}
                searchInput={searchInput}
                isNext={jobViewResponsiveGetData?.responsiveCurrentJobStep === 0 ? false : true}
            />
            <HeaderContentBlock
                sortOnClick={() => setSortModal(true)}
                closeOnClick={() => handleClose()}
                saveOnClick={() => getJobCreate()}
                addNewOnClick={() => gotoStep1()}
                nextStep={jobStep}
                addNewBtnText={'Add new job'}
                headerText="Your Jobs"
                isDisabled={btnDisabled || selectEmployee?.length === 0 ? true : false}
                searchOnchange={handleSearch}
                jobCompanyList={jobCompanyList}
                searchInput={searchInput}
            />
            {isMobile < breakPoints.tablet && (
                <div className="pt-5 pb-0 w-full hidden max-lg:block">
                    {jobStep === 0 && (
                        <>
                            {jobListData?.length === 0 && (
                                <div
                                    className=" bg-white max-md:h-10 flex justify-center items-center max-lg:font-semibold
                         max-lg:text-xl max-lg:h-12  max-md:font-medium max-md:text-lg"
                                >
                                    No Job Record Found
                                </div>
                            )}
                            <ResponsiveJobListAccordion
                                jobListData={jobListData}
                                openAcoordionResponsive={openAcoordionResponsive}
                                setOpenAcoordionResponsive={setOpenAcoordionResponsive}
                                employeeResponsive={employeeResponsive}
                                GetStartDate={GetStartDate}
                                GetEndDate={GetEndDate}
                                Getstatus={Getstatus}
                                onRowClick={onRowClick}
                            />
                            {selectedValue?.value !== general.INPROGRESS && filterApply !== 0 && (
                                <div className="mt-5">
                                    <Paginator
                                        first={first}
                                        rows={rows}
                                        totalRecords={totalJobRecords}
                                        onPageChange={onPageChange}
                                        className="datatable_pagination datatable_pagination_joblist"
                                        template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {jobStep === 1 && <JobCreateStep1 setJobStep={setJobStep} listData={listData} setListData={setListData} step1Data={step1Data} setStep1Data={setStep1Data} />}
                    {jobStep === 2 && (
                        <JobCreateStep2
                            step1Data={step1Data}
                            selectEmployee={selectEmployee}
                            setSelectEmployee={setSelectEmployee}
                            isBack={isBack}
                            setBtnDisabled={setBtnDisabled}
                            isDisabled={btnDisabled || selectEmployee?.length === 0 ? true : false}
                            saveOnClick={() => getJobCreate()}
                        />
                    )}
                </div>
            )}
            {isMobile >= breakPoints.tablet && (
                <div className="pt-6 pb-0 w-full your_jobs max-lg:hidden">
                    {jobStep === 0 && (
                        <>
                            <DataTable value={jobListData} tableClassName="logdatatable jobdatatable" onRowClick={onRowClick} emptyMessage="No Job Record Found" className="">
                                <Column field="comp" header="Comp." body={Getcomp}></Column>
                                <Column field="jobtype" header="Job type" body={Getjobtype}></Column>
                                <Column field="jobConfirmation" header="Job ID"></Column>
                                <Column field="assignemply" header="Assigned Employee" body={Getassignemply}></Column>
                                <Column field="location" header="Location"></Column>
                                <Column field="startdate" header="Start Date" body={GetStartDate}></Column>
                                <Column field="enddate" header="End Date" body={GetEndDate}></Column>
                                <Column field="departmentName" header="Department"></Column>
                                <Column field="status" header="Status" body={Getstatus}></Column>
                            </DataTable>
                            {selectedValue?.value !== general.INPROGRESS && filterApply !== 0 && (
                                <Paginator
                                    first={first}
                                    rows={rows}
                                    totalRecords={totalJobRecords}
                                    onPageChange={onPageChange}
                                    className="datatable_pagination datatable_pagination_joblist"
                                    template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                />
                            )}
                        </>
                    )}
                    {jobStep === 1 && <JobCreateStep1 setJobStep={setJobStep} listData={listData} setListData={setListData} step1Data={step1Data} setStep1Data={setStep1Data} />}
                    {jobStep === 2 && <JobCreateStep2 step1Data={step1Data} selectEmployee={selectEmployee} setSelectEmployee={setSelectEmployee} isBack={isBack} setBtnDisabled={setBtnDisabled} />}
                </div>
            )}
            {sortModal && (
                <SortFilterContentModal setSortModal={setSortModal} onFilterClear={handleFilterClear} onFilterApply={handleFilterApply} isJob={true} isModalOpen={sortModal}>
                    <JobSortFilter
                        listData={listData}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                        filterBy={filterBy}
                        setFilterBy={setFilterBy}
                        dateProps={dateProps}
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                    />
                </SortFilterContentModal>
            )}
        </div>
    );
};
export default Job;
