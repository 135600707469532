/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { forwardRef, useRef, useState } from 'react';
import westImg from 'src/assets/img/westImg.png';
import logImg from 'src/assets/img/logImg.png';
import fsImg from 'src/assets/img/fsImg.png';
import { TiFlash } from 'react-icons/ti';
import IconWithButton from 'src/components/Common/IconWithButton';
import { BsDot } from 'react-icons/bs';
import historyIcon from 'src/assets/svgs/historyIcon.svg';
import calenderIcon from 'src/assets/svgs/calenderIcon.svg';
import { HiChevronDown } from 'react-icons/hi';
import circleIcon from 'src/assets/svgs/plusCircleIcon.svg';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import TimePicker from 'react-time-picker';
import DatePicker from 'react-datepicker';
import { dateTimeFormate } from 'src/utils/function';
import closeIcon from 'src/assets/img/closeImg.png';
import moment from 'moment';
import { companyName, validation } from 'src/constants/general';
import { FaCircleExclamation } from 'react-icons/fa6';
import backButtonImg from 'src/assets/img/employeeBackBtnImg.png';

const SidebarContentBlock = ({ isDetailInfo = false, setHistoryBtn, listData, setListData, setStep1Data, step1Data, error, setError, updateJob, isDisabled, setResponsiveScreen }: any) => {
    const workerList = ['1', '2', '3', '4'];
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenAssignor, setModalOpenAssignor] = useState(false);
    const [modalOpenDepartment, setModalOpenDepartment] = useState(false);
    const [requestorName, setRequestorName] = useState('');
    const [searchRequestorName, setSearchRequestorName] = useState([]);
    const [assignorName, setAssignorName] = useState('');
    const [searchAssignorName, setSearchAssignorName] = useState([]);
    const [departmentName, setDepartmentName] = useState('');
    const [searchDepartmentName, setSearchDepartmentName] = useState([]);
    const modalRef = useRef<HTMLDivElement>(null);
    const modalRefAssignor = useRef<HTMLDivElement>(null);
    const modalRefDepartment = useRef<HTMLDivElement>(null);
    const [calenderOpen, setCalenderOpen] = useState({
        start: false,
        end: false,
    });
    const isStatus = ['Done', 'Cancelled'];
    const requestorFilter = listData.requestorList?.filter((item: any) => item.name === step1Data.requestorName);
    const assignorFilter = listData.assignorList?.filter((item: any) => item.name === step1Data.assignorName);
    const departmentFilter = listData.departmentList?.filter((item: any) => item.name === step1Data.departmentName);

    const openModal = () => {
        setModalOpen(true);
    };
    const openModalAssignor = () => {
        setModalOpenAssignor(true);
    };
    const openModalDepartment = () => {
        setModalOpenDepartment(true);
    };
    const toDay = moment();
    useOnClickOutside(modalRef, () => setModalOpen(false));
    useOnClickOutside(modalRefDepartment, () => setModalOpenDepartment(false));
    useOnClickOutside(modalRefAssignor, () => setModalOpenAssignor(false));

    const requestorOnclick = () => {
        if (requestorName) {
            const requestorObject = {
                name: requestorName,
                id: null,
            };
            setListData((prevData: any) => ({
                ...prevData,
                requestorList: [requestorObject, ...listData.requestorList],
            }));
            setRequestorName('');
            setSearchRequestorName([]);
            handleSelectChange(requestorObject.id, 'requestor', requestorObject.name);
        }
    };
    const assignorOnClick = () => {
        if (assignorName) {
            const assignorObject = {
                name: assignorName,
                id: null,
            };
            setListData((prevData: any) => ({
                ...prevData,
                assignorList: [assignorObject, ...listData.assignorList],
            }));
            setAssignorName('');
            setSearchAssignorName([]);
            handleSelectChange(assignorObject.id, 'assignor', assignorObject.name);
        }
    };
    const departmentOnclick = () => {
        if (departmentName) {
            const departmentObject = {
                name: departmentName,
                id: null,
            };
            setListData((prevData: any) => ({
                ...prevData,
                departmentList: [departmentObject, ...listData.departmentList],
            }));
            setDepartmentName('');
            setSearchDepartmentName([]);
            handleSelectChange(departmentObject.id, 'department', departmentObject.name);
        }
    };

    const requestorOnchange = (e: any) => {
        const requestorSearch = listData.requestorList?.filter((item: any) => item?.name?.toLowerCase()?.includes(e.target.value?.toLowerCase()));
        setRequestorName(e.target.value);
        setSearchRequestorName(requestorSearch);
    };
    const deparmentOnchange = (e: any) => {
        const departmentSearch = listData.departmentList?.filter((item: any) => item?.name?.toLowerCase()?.includes(e.target.value?.toLowerCase()));
        setDepartmentName(e.target.value);
        setSearchDepartmentName(departmentSearch);
    };

    const assignorOnChange = (e: any) => {
        const departmentSearch = listData.assignorList?.filter((item: any) => item?.name?.toLowerCase()?.includes(e.target.value?.toLowerCase()));
        setAssignorName(e.target.value);
        setSearchAssignorName(departmentSearch);
    };

    const handleSelectChange = (event: any, type: string, reqName?: string) => {
        if (type === 'location') {
            setStep1Data((prevData: any) => ({
                ...prevData,
                location: event.target.value,
            }));
        } else if (type === 'requestor') {
            setStep1Data((prevData: any) => ({
                ...prevData,
                requestor: event,
                requestorName: reqName,
            }));
            setModalOpen(false);
            setRequestorName('');
            setSearchRequestorName([]);
        } else if (type === 'assignor') {
            setStep1Data((prevData: any) => ({
                ...prevData,
                assignor: event,
                assignorName: reqName,
            }));
            setModalOpenAssignor(false);
            setAssignorName('');
            setSearchAssignorName([]);
        } else if (type === 'department') {
            setStep1Data((prevData: any) => ({
                ...prevData,
                department: event,
                departmentName: reqName,
            }));
            setModalOpenDepartment(false);
            setDepartmentName('');
            setSearchDepartmentName([]);
        }
    };
    const handleChange = (event: any) => {
        let inputValue = event.target.value;
        setError((prevData: any) => ({
            ...prevData,
            jobConfirm: '',
        }));

        const onlyDigits = inputValue.replace(/\D/g, '');

        let formattedInput = onlyDigits.length > 0 ? `1000-${onlyDigits.slice(4)}` : '1000-';
        formattedInput = formattedInput.slice(0, 10);
        setStep1Data((prevData: any) => ({
            ...prevData,
            jobConfirmation: formattedInput,
        }));
    };
    const makeTimeFormate = (formateTime: any, formateDate: any) => {
        const formattedTime = moment(formateTime, 'HH:mm').format('hh:mma');
        const newDate = moment(formateDate, 'ddd MMM DD YYYY HH:mm:ss ZZ').format('YYYY-MM-DD');
        const originalDateTime = `${newDate} ${formattedTime}`;
        const formattedDate = moment(originalDateTime, 'YYYY-MM-DD hh:mma').format('YYYY-MM-DD HH:mm:ss');
        return formattedDate;
    };
    const timeOnChange = (e: any, type: string) => {
        setError((prevData: any) => ({
            ...prevData,
            date: '',
        }));
        if (type === 'start') {
            const mainDate = makeTimeFormate(e, step1Data.value.startDate);
            setStep1Data((prevData: any) => ({
                ...prevData,
                value: { ...step1Data.value, startTime: e },
                startDate: mainDate,
            }));
        } else if (type === 'end') {
            const mainDate = makeTimeFormate(e, step1Data.value.endDate);
            setStep1Data((prevData: any) => ({
                ...prevData,
                value: { ...step1Data.value, endTime: e },
                endDate: step1Data.endDate ? mainDate : '',
            }));
        }
    };

    const onChange = (e: any, type: string) => {
        setError((prevData: any) => ({
            ...prevData,
            date: '',
        }));
        if (type === 'start') {
            const mainDate = makeTimeFormate(step1Data.value.startTime, e);
            setStep1Data((prevData: any) => ({
                ...prevData,
                value: { ...step1Data.value, startDate: e },
                startDate: mainDate,
            }));
        } else if (type === 'end') {
            const mainDate = makeTimeFormate(step1Data.value.endTime, e);
            setStep1Data((prevData: any) => ({
                ...prevData,
                value: { ...step1Data.value, endDate: e },
                endDate: mainDate,
            }));
        }
    };

    const handleTextAreaChange = (event: any) => {
        setStep1Data((prevData: any) => ({
            ...prevData,
            notes: event.target.value,
        }));
    };
    const checkBoxChange = (e: any) => {
        setStep1Data((prevData: any) => ({
            ...prevData,
            asapStatus: e.target.checked,
        }));
    };
    const checkBoxChangeMandatory = (e: any) => {
        setStep1Data((prevData: any) => ({
            ...prevData,
            mandatoryStatus: e.target.checked,
        }));
    };
    const CustomStartInput = forwardRef((props, ref: any) => (
        <div ref={ref} className="sidebar_date">
            <IconWithButton
                title={`${dateTimeFormate(step1Data?.value?.startDate, 'DD MMM, YYYY')}`}
                icon={calenderIcon}
                onclick={() =>
                    setCalenderOpen((prevData) => ({
                        ...prevData,
                        start: true,
                    }))
                }
                isDisabled={isStatus.includes(step1Data.status) ? true : false}
            />
        </div>
    ));

    const CustomEndInput = forwardRef((props, ref: any) => (
        <div ref={ref} className="sidebar_date">
            <IconWithButton
                title={step1Data?.value?.endDate ? `${dateTimeFormate(step1Data?.value?.endDate, 'DD MMM, YYYY')}` : 'End Date'}
                icon={calenderIcon}
                onclick={() => setCalenderOpen((prevData) => ({ ...prevData, end: true }))}
                isDisabled={isStatus.includes(step1Data.status) ? true : false}
                isMoreClass={step1Data?.value?.endDate ? '' : 'w-[148.05px] !justify-start'}
            />
        </div>
    ));

    const getCurrentColor = (status: any) => {
        let color;
        if (status === 'Done') {
            color = 'text-[#36B845]';
        } else if (status === 'Cancelled') {
            color = 'text-[#7A3CE3]';
        } else if (status === 'In Progress') {
            color = 'text-[#FFCC15]';
        } else {
            color = 'text-[#FFFFFF]';
        }
        return color;
    };
    return (
        <>
            {isDetailInfo && (
                <div className="flex justify-between max-lg:hidden">
                    <div className="flex items-center gap-2">
                        {isStatus.includes(step1Data.status) ? (
                            ''
                        ) : (
                            <button
                                className="w-8 h-8 p-2 bg-zinc-100 rounded-lg flex-col justify-center items-center gap-2 inline-flex"
                                onClick={() => {
                                    setHistoryBtn(true);
                                    setResponsiveScreen('historyList');
                                }}
                            >
                                <img src={historyIcon} alt="historyicon" className="h-5 w-5" />
                            </button>
                        )}
                        <h2 className="text-black text-base font-black">Job Details</h2>
                    </div>
                    <div>
                        <p className={`${getCurrentColor(step1Data.status)} text-xs font-normal flex items-center`}>
                            <BsDot className="h-8 w-8" />
                            {step1Data.status}
                        </p>
                    </div>
                </div>
            )}
            {isDetailInfo && (
                <div className="hidden justify-between max-lg:flex">
                    <div className="flex items-center gap-2">
                        <button
                            className="w-8 h-8 p-1 bg-zinc-100 rounded-lg flex-col justify-center items-center gap-2 hidden max-md:inline-flex"
                            onClick={() => {
                                setResponsiveScreen('activitylist');
                            }}
                        >
                            <img src={backButtonImg} alt="backButtonImg" />
                        </button>

                        <h2 className="text-black text-base font-black">Job Details</h2>
                    </div>
                    <div>
                        <p className={`${getCurrentColor(step1Data.status)} text-xs font-normal flex items-center`}>
                            <BsDot className="h-8 w-8" />
                            {step1Data.status}
                        </p>
                    </div>
                    {isStatus.includes(step1Data.status) ? (
                        ''
                    ) : (
                        <button
                            className="w-8 h-8 p-2 bg-zinc-100 rounded-lg flex-col justify-center items-center gap-2 inline-flex"
                            onClick={() => {
                                setHistoryBtn(true);
                                setResponsiveScreen('historyList');
                            }}
                        >
                            <img src={historyIcon} alt="historyicon" className="h-5 max-w-5" />
                        </button>
                    )}
                </div>
            )}
            {!isDetailInfo && <h2 className="max-md:text-base font-black hidden max-lg:block max-lg:text-lg">Job Details</h2>}
            <div className="mt-2 max-lg:mt-5">
                <label htmlFor="asapStatus" className="flex justify-between">
                    {' '}
                    <div className="flex items-center">
                        <span className="w-5 h-5 rounded-full bg-red-50 text-red500 flex justify-center items-center mr-2">
                            <TiFlash />
                        </span>
                        <span className="text-gray80 text-xs" style={{ textWrap: 'balance' }}>
                            Check To Enable Asap Status
                        </span>
                    </div>
                    <input
                        type="checkbox"
                        id="asapStatus"
                        name="asapStatus"
                        value="asapStatus"
                        checked={step1Data.asapStatus}
                        className="accent-black cursor-pointer"
                        onChange={(e) => checkBoxChange(e)}
                        disabled={isStatus.includes(step1Data.status) ? true : false}
                    />
                </label>
            </div>
            <div className="mt-4">
                <label htmlFor="mandatoryStatus" className="flex justify-between">
                    {' '}
                    <div className="flex items-center">
                        <span className="w-5 h-5 rounded-full bg-white text-blue-800 flex justify-center items-center mr-2">
                            <FaCircleExclamation />
                        </span>
                        <span className="text-gray80 text-xs" style={{ textWrap: 'balance' }}>
                            Check To Enable Mandatory Status
                        </span>
                    </div>
                    <input
                        type="checkbox"
                        id="mandatoryStatus"
                        name="mandatoryStatus"
                        value="mandatoryStatus"
                        checked={step1Data.mandatoryStatus}
                        className="accent-black cursor-pointer"
                        onChange={(e) => checkBoxChangeMandatory(e)}
                        disabled={isStatus.includes(step1Data.status) ? true : false}
                    />
                </label>
            </div>

            <div className="mt-4">
                <p className="text-gray80 text-xs font-normal mb-2">Comapny</p>
                <div className="flex gap-2">
                    {listData.companyList?.map((data: any, index: number) => (
                        <div key={index}>
                            <IconWithButton
                                title={data?.name}
                                icon={data?.name === companyName.West ? westImg : data?.name === companyName.Log ? logImg : fsImg}
                                isColor={step1Data.companyType.name === data.name ? true : false}
                                onclick={() =>
                                    setStep1Data((prevData: any) => ({
                                        ...prevData,
                                        companyType: {
                                            name: data.name,
                                            id: data.id,
                                        },
                                    }))
                                }
                                isDisabled={isStatus.includes(step1Data.status) ? true : false}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="mt-4">
                <p className="text-gray80 text-xs font-normal mb-2">Job Type</p>
                <div className="flex gap-2">
                    {listData.jobList?.map((data: any, index: number) => (
                        <div key={index}>
                            <IconWithButton
                                title={data.name}
                                isColor={step1Data.jobType.name === data.name ? true : false}
                                onclick={() =>
                                    setStep1Data((prevData: any) => ({
                                        ...prevData,
                                        jobType: {
                                            name: data.name,
                                            id: data.id,
                                        },
                                    }))
                                }
                                isDisabled={isStatus.includes(step1Data.status) ? true : false}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="mt-4">
                <p className="text-gray80 text-xs font-normal">Job Confirmation {isDetailInfo ? '' : '#'}</p>
                <input
                    className="max-w-full h-10 w-56 px-4 py-3 bg-zinc-100 rounded-xl mt-2 text-zinc-400 text-xs font-normal focus:bg-gray10 focus-visible:outline-none focus:text-primary max-lg:w-full"
                    placeholder="Enter the  Nunmber"
                    onChange={handleChange}
                    type="text"
                    value={step1Data.jobConfirmation}
                    disabled={isStatus.includes(step1Data.status) ? true : false}
                />
                {error.jobConfirm && <p className="text-red-600 mt-1 text-sm">{error.jobConfirm}</p>}
            </div>
            <div className="mt-4">
                <p className="text-gray80 text-xs font-normal">Workes</p>
                <div className="flex gap-2 mt-2">
                    {workerList.map((data, index) => (
                        <div key={index}>
                            <IconWithButton
                                title={data}
                                isColor={data === step1Data.workers ? true : false}
                                onclick={() =>
                                    setStep1Data((prevData: any) => ({
                                        ...prevData,
                                        workers: data,
                                    }))
                                }
                                isDisabled={isStatus.includes(step1Data.status) ? true : false}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="mt-3 relative">
                <div
                    className={`relative py-2 px-4 border rounded-xl flex items-center justify-between ${isStatus.includes(step1Data.status) ? '' : 'cursor-pointer'} border-gray40`}
                    onClick={() => (isStatus.includes(step1Data.status) ? '' : openModal())}
                >
                    <div>
                        <p className=" text-gray80 text-[10px] font-normal font-['Satoshi Variable']">Requestor</p>
                        <p className="text-black text-sm font-medium font-['Satoshi Variable']">{requestorFilter[0]?.name}</p>
                    </div>
                    <span className="text-gray80">
                        <HiChevronDown size={20} />
                    </span>
                </div>

                <div ref={modalRef}>
                    {modalOpen && (
                        <div className="py-2 border top-[8%] left-0 absolute bg-black text-white w-[315px] z-10 rounded-xl add_job_dropdown max-lg:w-full">
                            <ul className="h-24 overflow-auto">
                                {searchRequestorName?.length === 0 && requestorName === ''
                                    ? listData.requestorList?.map((data: any, index: number) => (
                                          <li className=" cursor-pointer pb-2 hover:bg-black60 px-4" key={index} onClick={() => handleSelectChange(data.id, 'requestor', data.name)}>
                                              {data.name}
                                          </li>
                                      ))
                                    : searchRequestorName?.map((data: any, index: number) => (
                                          <li className=" cursor-pointer pb-2 hover:bg-black60 px-4" key={index} onClick={() => handleSelectChange(data.id, 'requestor', data.name)}>
                                              {data.name}
                                          </li>
                                      ))}
                            </ul>
                            <div className="relative">
                                <input
                                    className="w-full h-14 px-5 py-2 pr-10 bg-black text-white focus-visible:outline-none"
                                    placeholder="Enter the name"
                                    value={requestorName}
                                    onChange={requestorOnchange}
                                    maxLength={validation.requestor}
                                />
                                <img className="w-6 h-6 absolute right-3 top-4 cursor-pointer" src={circleIcon} alt="searchicon" onClick={() => requestorOnclick()} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-3 relative">
                <div
                    className={`relative py-2 px-4 border rounded-xl flex items-center justify-between ${isStatus.includes(step1Data.status) ? '' : 'cursor-pointer'} border-gray40`}
                    onClick={() => (isStatus.includes(step1Data.status) ? '' : openModalAssignor())}
                >
                    <div>
                        <p className=" text-gray80 text-[10px] font-normal font-['Satoshi Variable']">Assignor</p>
                        <p className="text-black text-sm font-medium font-['Satoshi Variable']">{assignorFilter?.[0]?.name}</p>
                    </div>
                    <span className="text-gray80">
                        <HiChevronDown size={20} />
                    </span>
                </div>

                <div ref={modalRefAssignor}>
                    {modalOpenAssignor && (
                        <div className="py-2 border top-[8%] left-0 absolute bg-black text-white w-[315px] z-10 rounded-xl add_job_dropdown max-lg:w-full">
                            <ul className="h-24 overflow-auto">
                                {searchAssignorName?.length === 0 && assignorName === ''
                                    ? listData.assignorList?.map((data: any, index: number) => (
                                          <li className=" cursor-pointer pb-2 hover:bg-black60 px-4" key={index} onClick={() => handleSelectChange(data.id, 'assignor', data.name)}>
                                              {data.name}
                                          </li>
                                      ))
                                    : searchAssignorName?.map((data: any, index: number) => (
                                          <li className=" cursor-pointer pb-2 hover:bg-black60 px-4" key={index} onClick={() => handleSelectChange(data.id, 'assignor', data.name)}>
                                              {data.name}
                                          </li>
                                      ))}
                            </ul>
                            <div className="relative">
                                <input
                                    className="w-full h-14 px-5 py-2 pr-10 bg-black text-white focus-visible:outline-none"
                                    placeholder="Enter the name"
                                    value={assignorName}
                                    onChange={assignorOnChange}
                                    maxLength={validation.assignor}
                                />
                                <img className="w-6 h-6 absolute right-3 top-4 cursor-pointer" src={circleIcon} alt="searchicon" onClick={() => assignorOnClick()} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {!isDetailInfo && (
                <div className="mt-3 relative">
                    <div
                        className={`relative py-2 px-4 border rounded-xl flex items-center justify-between ${isStatus.includes(step1Data.status) ? '' : 'cursor-pointer'} border-gray40`}
                        onClick={() => (isStatus.includes(step1Data.status) ? '' : openModalDepartment())}
                    >
                        <div>
                            <p className=" text-gray80 text-[10px] font-normal font-['Satoshi Variable']">Department</p>
                            <p className="text-black text-sm font-medium font-['Satoshi Variable']">{departmentFilter[0]?.name}</p>
                        </div>
                        <span className="text-gray80">
                            <HiChevronDown size={20} />
                        </span>
                    </div>

                    <div ref={modalRefDepartment}>
                        {modalOpenDepartment && (
                            <div className="py-2 border top-[8%] left-0 absolute bg-black text-white w-[315px] z-10 rounded-xl add_job_dropdown max-lg:w-full">
                                <ul className="h-24 overflow-auto">
                                    {searchDepartmentName?.length === 0 && departmentName === ''
                                        ? listData.departmentList?.map((data: any, index: number) => (
                                              <li className=" cursor-pointer pb-2 hover:bg-black60 px-4" key={index} onClick={() => handleSelectChange(data.id, 'department', data.name)}>
                                                  {data.name}
                                              </li>
                                          ))
                                        : searchDepartmentName?.map((data: any, index: number) => (
                                              <li className=" cursor-pointer pb-2 hover:bg-black60 px-4" key={index} onClick={() => handleSelectChange(data.id, 'department', data.name)}>
                                                  {data.name}
                                              </li>
                                          ))}
                                </ul>
                                <div className="relative">
                                    <input
                                        className="w-full h-14 px-5 py-2 pr-10 bg-black text-white focus-visible:outline-none"
                                        placeholder="Enter the name"
                                        value={departmentName}
                                        onChange={deparmentOnchange}
                                        maxLength={validation.requestor}
                                    />
                                    <img className="w-6 h-6 absolute right-3 top-4 cursor-pointer" src={circleIcon} alt="searchicon" onClick={() => departmentOnclick()} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            <div className="mt-3">
                <div className="relative">
                    <select
                        id="selectBox"
                        className={`select_floating focus-visible:outline-none peer ${isStatus.includes(step1Data.status) ? 'cursor-auto' : 'cursor-pointer'}`}
                        value={step1Data.location}
                        onChange={(e) => {
                            handleSelectChange(e, 'location');
                        }}
                        disabled={isStatus.includes(step1Data.status) ? true : false}
                    >
                        {listData.locationList?.map((data: any, index: number) => (
                            <option key={index} value={data.id}>
                                {data.name}
                            </option>
                        ))}
                    </select>
                    <label htmlFor="selectBox" className="floating_label">
                        Location
                    </label>
                    <span className="absolute top-1/2 -translate-y-1/2 right-3 text-gray80">
                        <HiChevronDown size={20} />
                    </span>
                </div>
            </div>

            {isDetailInfo && (
                <>
                    <div className="mt-4 flex justify-between">
                        <DatePicker
                            selected={step1Data.value.startDate}
                            onChange={(e) => {
                                onChange(e, 'start');
                            }}
                            open={calenderOpen.start}
                            onClickOutside={() =>
                                setCalenderOpen((prevData) => ({
                                    ...prevData,
                                    start: false,
                                }))
                            }
                            customInput={<CustomStartInput />}
                            showPopperArrow={false}
                            calendarClassName="sort-custom-datepicker"
                            // minDate={toDay.toDate()}
                        >
                            <div className="inline-flex job-filter-btn pb-2">
                                <IconWithButton title={`${dateTimeFormate(step1Data?.value?.startDate, 'DD MMM, YYYY')}`} />
                                <button
                                    className="ms-5"
                                    onClick={() =>
                                        setCalenderOpen((prevData) => ({
                                            ...prevData,
                                            start: false,
                                        }))
                                    }
                                >
                                    <img src={closeIcon} className="w-6 h-6 rounded-full me-2" alt="closeIcon" />
                                </button>
                            </div>
                        </DatePicker>
                        <div className="w-[90px] h-8 border-b border-stone-300 justify-end items-center flex">
                            <TimePicker
                                onChange={(e) => timeOnChange(e, 'start')}
                                value={step1Data.value.startTime}
                                clearIcon={null}
                                clockIcon={null}
                                disableClock={true}
                                hourPlaceholder="00"
                                minutePlaceholder="00"
                                // maxTime="11:59"
                                format="hh:mm a"
                                className="popup-time-picker"
                                disabled={isStatus.includes(step1Data.status) ? true : false}
                            />
                        </div>
                    </div>
                    <div className="mt-4 flex justify-between">
                        <DatePicker
                            selected={step1Data.value.endDate}
                            onChange={(e: Date | null) => {
                                onChange(e, 'end');
                            }}
                            open={calenderOpen.end}
                            onClickOutside={() =>
                                setCalenderOpen((prevData) => ({
                                    ...prevData,
                                    end: false,
                                }))
                            }
                            customInput={<CustomEndInput />}
                            showPopperArrow={false}
                            calendarClassName="sort-custom-datepicker"
                            minDate={toDay.toDate()}
                        >
                            <div className="inline-flex job-filter-btn pb-2">
                                <IconWithButton title={step1Data?.value?.endDate ? `${dateTimeFormate(step1Data?.value?.endDate, 'DD MMM, YYYY')}` : 'End Date'} />
                                <button
                                    className="ms-5"
                                    onClick={() =>
                                        setCalenderOpen((prevData) => ({
                                            ...prevData,
                                            end: false,
                                        }))
                                    }
                                >
                                    <img src={closeIcon} className="w-6 h-6 me-2 rounded-full" alt="closeIcon" />
                                </button>
                            </div>
                        </DatePicker>
                        <div className=" w-[90px] h-8 border-b border-stone-300 justify-end items-center flex">
                            <TimePicker
                                onChange={(e) => timeOnChange(e, 'end')}
                                value={step1Data.value.endTime}
                                clearIcon={null}
                                clockIcon={null}
                                disableClock={true}
                                hourPlaceholder="00"
                                minutePlaceholder="00"
                                // maxTime="11:59"
                                format="hh:mm a"
                                className="popup-time-picker"
                                disabled={isStatus.includes(step1Data.status) ? true : false}
                            />
                        </div>
                    </div>
                    {error.date && <p className="text-red-600 mt-1 text-sm">{error.date}</p>}
                    <div className="mt-2">
                        <p className="text-gray80 text-xs font-normal">Notes</p>
                        <textarea
                            id="myTextArea"
                            value={step1Data.notes}
                            onChange={handleTextAreaChange}
                            rows={4}
                            cols={50}
                            className="h-16 w-full px-4 py-3 bg-zinc-100 rounded-xl mt-2 text-zinc-400 text-xs font-normal scrollbar-default"
                            placeholder="Enter anything you want"
                            style={{ resize: 'none', outline: 'none' }}
                            disabled={isStatus.includes(step1Data.status) ? true : false}
                        />
                    </div>
                    {isStatus.includes(step1Data.status) ? (
                        ''
                    ) : (
                        <div className="mt-2">
                            <button
                                className={`w-full h-12 px-6 py-4 ${
                                    isDisabled ? 'bg-Green40' : 'bg-Green80'
                                } rounded-3xl shadow justify-center items-center gap-2 inline-flex text-white text-sm font-bold font-['Satoshi Variable'] uppercase`}
                                onClick={() => {
                                    updateJob();
                                }}
                                disabled={isDisabled}
                                type={'button'}
                            >
                                update the job
                            </button>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default SidebarContentBlock;
