export const general = {
    BEGINNER: 'Beginner',
    INTERMEDIATE: 'Intermediate',
    FLUENT: 'Fluent',
    AVAILABLE: 'Available',
    WORKING: 'Working',
    UNAVAILABLE: 'Unavailable',
    ON_HOLD: 'On Hold',
    BUSY: 'Busy',
    FIRED: 'Fired',
    QUIT: 'Quit',
    INPROGRESS: 'In Progress',
    DONE: 'Done',
    CANCELLED: 'Cancelled',
};

export const validation = {
    email: 256,
    password: 256,
    name: 30,
    surname: 30,
    requestor: 50,
    assignor: 50,
};

export const jobStatus = {
    inprogress: 'In Progress',
    fail: 'Failed',
    cancel: 'Cancelled',
    complete: 'Done',
    invoiced: 'Invoiced',
};

export const companyName = {
    West: 'West',
    Log: 'Log',
};

export const generalConstants = {
    superAdmin: 'superAdmin',
    spentTimeInseconds: 60,
};

export const breakPoints = {
    mobile: 768,
    tablet: 1280,
};
