import React from 'react';
import trophyIcon from 'src/assets/svgs/trophy.svg';
import profileImg from 'src/assets/img/profileImg.png';
import starIcon from 'src/assets/svgs/starIcon.svg';
import { BsDot } from 'react-icons/bs';
import { convertedHours, getEmployeeOfTheHeading, getRemovetzTime, roundedNumber } from 'src/utils/function';

const EmployeeOfWeek = ({ peopleOverViewData, duration }: any) => (
    <div className="flex flex-col h-full">
        <div className="flex items-center justify-between mb-4">
            <h5 className="text-black80 text-xxl-base text-sm font-medium">{`Employee of the ${getEmployeeOfTheHeading(duration)}`}</h5>
            <p className="text-gray80 text-xxl-xs text-[11px] font-normal">{`${getRemovetzTime(peopleOverViewData?.filterStartDate, 'DD MMM')} - ${getRemovetzTime(
                peopleOverViewData?.filterEndDate,
                'DD MMM, YYYY',
            )}`}</p>
        </div>
        <div className="flex gap-2 flex-1">
            <div className="p-4 flex-1 bg-yellow20 rounded-2xl">
                <div className="relative">
                    <img className="w-16 h-16 rounded-3xl" src={profileImg} alt="trophy" />
                    <img className="w-4 h-4 absolute rounded-3xl mt-[-15px] ml-11" src={trophyIcon} alt="trophy" />
                </div>
                <h5 className="text-black80 text-sm font-medium mt-3 lg:mt-2 xl:mt-3">{`${peopleOverViewData?.employeeOfTheRange?.name} ${peopleOverViewData?.employeeOfTheRange?.surname}`}</h5>
                <div className="mt-3 lg:mt-2 xl:mt-3 flex items-center gap-1">
                    <img className="w-3 h-3" src={starIcon} alt="staricon" />
                    <p className="text-gray100 text-sm font-medium">{roundedNumber(peopleOverViewData?.employeeOfTheRange?.rating)}</p>
                </div>
                <div className="flex mt-3 lg:mt-2 xl:mt-3 gap-2">
                    <div className="w-12 h-5 bg-gray10 text-black80 text-xs font-medium rounded-3xl border border-gray10 justify-center items-center gap-4 inline-flex">
                        <div>{peopleOverViewData?.employeeOfTheRange?.companyName}</div>
                    </div>
                    <div className="w-16 h-5 bg-gray10 text-black80 text-xs font-medium rounded-3xl border border-gray10 justify-center items-center gap-4 inline-flex">
                        <div>{peopleOverViewData?.employeeOfTheRange?.jobType}</div>
                    </div>
                </div>
            </div>
            <div className="p-4 px-2 pb-0 w-1/2">
                <ul className="">
                    <li className="flex justify-between mb-2">
                        <span className="text-black80 text-sm font-medium">Total Jobs</span>
                        <span className="text-gray100 text-sm font-medium">{peopleOverViewData?.employeeOfTheRange?.total_jobs}</span>
                    </li>
                    {peopleOverViewData?.employeeOfTheRange?.jobTypeTotal &&
                        peopleOverViewData?.employeeOfTheRange?.jobTypeTotal?.map((data: any, index: number) => (
                            <li className="flex justify-between mb-2" key={index}>
                                <span className="flex items-center text-black80 text-sm font-normal">
                                    <BsDot />
                                    {data.jobType}
                                </span>{' '}
                                <span className="text-black text-sm font-normal">{data.jobCount}</span>
                            </li>
                        ))}
                </ul>
                <div className="mt-4 rounded-lg bg-[#F6F6F8] py-2">
                    <h4 className="text-gray80 text-xs font-medium text-center">Total Hours</h4>
                    <p className="text-black80 text-[32px] font-black text-center mt-2">{convertedHours(peopleOverViewData?.employeeOfTheRange?.total_hours)}</p>
                </div>
            </div>
        </div>
    </div>
);

export default EmployeeOfWeek;
