import React from 'react';
import trophyIcon from 'src/assets/svgs/trophy.svg';
import { generalConstants } from 'src/constants/general';
import { convertedHours } from 'src/utils/function';

const GoodPerformers = ({ peopleOverViewData, userData, title }: any) => (
    <div className="py-4">
        <div className="text-black80 text-xxl-base text-sm font-medium leading-4 px-6 max-md:px-2">{title}</div>

        <ul className={`mt-4 job_history ${userData?.userType === generalConstants.superAdmin ? 'h-[300px] max-lg:h-[300px]' : 'h-[300px]'}  overflow-y-auto px-6 max-md:px-2`}>
            {peopleOverViewData?.length !== 0 ? (
                peopleOverViewData?.map((data: any, index: number) =>
                    title === 'Good Performers' ? (
                        <li className="flex justify-between items-center border-b py-2 my-1 last:border-b-0" key={index}>
                            <div className="flex items-center">
                                {data?.reward === 1 && <img className="w-5 h-5 rounded-3xl max-md:w-4 max-md:h-4" src={trophyIcon} alt="trophy" />}
                                <h3 className="text-black80 text-sm font-medium ml-2 max-md:ml-1">{`${data.name} ${data.surname}`}</h3>
                                <div className="flex gap-2 ml-3 max-md:ml-2 max-md:gap-1">
                                    <div className="w-14 h-6 text-black80 text-[11px] font-medium bg-gray10 rounded-3xl border border-gray10 justify-center items-center gap-4 inline-flex max-md:gap-2">
                                        <div>{data.companyName}</div>
                                    </div>
                                    <div className="w-[68px] h-6 text-black80 text-[11px] font-medium bg-gray10 rounded-3xl border border-gray10 justify-center items-center gap-4 inline-flex max-md:gap-2">
                                        <div>{data.jobType}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-gray100 text-xs font-medium flex">
                                <p className="mr-6 max-md:mr-3">{data.total_jobs} job</p>
                                <p>{convertedHours(data.total_hours)} hrs.</p>
                            </div>
                        </li>
                    ) : title === 'Assignors' ? (
                        <li className="flex justify-between items-center border-b py-2 my-1 last:border-b-0" key={index}>
                            <div className="flex items-center">
                                <h3 className="text-black80 text-sm font-medium ml-2 max-md:ml-1">{data.assignorName}</h3>
                            </div>
                            <div className="text-gray100 text-xs font-medium flex">
                                <p className="mr-6 max-md:mr-3">{data.totalJobsAssigned} job</p>
                                {userData?.userType === generalConstants.superAdmin && <p>{convertedHours(data.totalWorkingHours)} hrs.</p>}
                            </div>
                        </li>
                    ) : (
                        <></>
                    ),
                )
            ) : (
                <div className="emptyMessageShow">No Records Found</div>
            )}
        </ul>
    </div>
);

export default GoodPerformers;
