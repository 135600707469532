import React, { useEffect, useState } from 'react';
import { BsDot } from 'react-icons/bs';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { generalConstants } from 'src/constants/general';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const MonthlyOverview = ({ chartOverViewData, jobCompany, userData }: any) => {
    const allCompletedZero = chartOverViewData?.completed?.every((value: any) => value === 0);
    const allInCompletedZero = chartOverViewData?.total?.every((value: any) => value === 0);
    const [newChatData, setNewChatData] = useState<any>([]);
    let currentDate = new Date();

    useEffect(() => {
        (async () => {
            if (chartOverViewData?.completed.length !== 0) {
                await setNewChatData(chartOverViewData);
                let newValues: any[] = [];

                // Define the sets of values and their corresponding date ranges
                const dateRanges = [
                    { endDate: new Date('2024-07-01'), logValues: [308, 258, 333, 346, 254], westValues: [331, 321, 373, 398, 274], fsValues: [0, 0, 0, 0, 0], totalValues: [639, 579, 706, 744, 528] },
                    { endDate: new Date('2024-08-01'), logValues: [258, 333, 346, 254], westValues: [321, 373, 398, 274], fsValues: [0, 0, 0, 0], totalValues: [579, 706, 744, 528] },
                    { endDate: new Date('2024-09-01'), logValues: [333, 346, 254], westValues: [373, 398, 274], fsValues: [0, 0, 0], totalValues: [706, 744, 528] },
                    { endDate: new Date('2024-10-01'), logValues: [346, 254], westValues: [398, 274], fsValues: [0, 0], totalValues: [744, 528] },
                    { endDate: new Date('2024-11-01'), logValues: [254], westValues: [274], fsValues: [0], totalValues: [528] },
                ];

                // Find the appropriate set of values based on the current date
                for (const range of dateRanges) {
                    if (currentDate < range.endDate) {
                        newValues = jobCompany.name === 'Log' ? range.logValues : jobCompany.name === 'West' ? range.westValues : jobCompany.name === 'FS' ? range.fsValues : range.totalValues;
                        break;
                    }
                }

                const newCompleted = [...chartOverViewData.completed];
                const newTotal = [...chartOverViewData.total];

                newValues.forEach((value, index) => {
                    newCompleted[index] = value;
                    newTotal[index] = value;
                });

                // Update the state with the new values
                setNewChatData((prevState: any) => ({
                    ...prevState,
                    completed: newCompleted,
                    total: newTotal,
                }));
            }
        })();
    }, [chartOverViewData]);

    const options: any = {
        radius: 0,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                // text: "Chart.js Line Chart",
            },
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'top',
                offset: (context: any) => (context.dataset.label === 'Income' ? -24 : -6),
                color: (context: any) => (context.dataset.label === 'Income' ? 'black' : 'navy'),
                formatter: (value: any) => (value === 0 ? '' : value),
            },
            tooltip: {
                enabled: false,
            },
        },
        scales: {
            // to remove the labels
            x: {
                ticks: {
                    // display: false,
                },

                // to remove the x-axis grid
                grid: {
                    // drawBorder: false,
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            // to remove the y-axis labels
            y: {
                ticks: {
                    display: false,
                    // beginAtZero: true,
                },
                // to remove the y-axis grid
                grid: {
                    drawBorder: true,
                    display: false,
                },
                border: {
                    display: false,
                },
            },
        },
    };

    const labels = currentDate >= new Date('2024-11-01') ? chartOverViewData?.months : newChatData?.months;

    const data = {
        labels,
        datasets: [
            {
                label: 'Income',
                data: currentDate >= new Date('2024-11-01') ? chartOverViewData?.total : newChatData?.total,
                backgroundColor: '#EFF0F1',
                borderRadius: 20,
            },
            {
                label: 'Completed',
                data: currentDate >= new Date('2024-11-01') ? chartOverViewData?.completed : newChatData?.completed,
                backgroundColor: '#627BE8',
                borderRadius: 20,
            },
        ],
    };
    return (
        <div className="w-full md:flex-1 p-4 bg-white rounded-2xl main-shadow mt-4 max-md:mt-4 max-lg:mt-5">
            <div className="flex justify-between max-md:flex-col max-md:gap-[10px]">
                <div className="flex items-center max-md:justify-between">
                    <h2 className="text-black text-sm font-black">Monthly `Overview</h2>
                    <p className="text-black80 text-xs font-medium ms-1 mt-[2px]">Last 6 month</p>
                </div>
                <div className="flex items-center max-md:justify-between">
                    <div className="flex items-center">
                        <div>
                            <p className="text-zinc-400 text-xs font-normal flex items-center me-2 max-md:-ms-[0.8rem]">
                                <BsDot className="h-8 w-8" />
                                <span className="-ms-2">Income</span>
                            </p>
                        </div>
                        <div>
                            <p className="text-zinc-400 text-xs font-normal flex items-center">
                                <BsDot className="h-8 w-8 text-blue-700" />
                                <span className="-ms-2">Completed</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center px-[6px] py-1 bg-zinc-100 rounded-lg border border-gray10 ms-5">
                        <HiOutlineDotsHorizontal className=" w-5 h-5" />
                    </div>
                </div>
            </div>
            <div className="w-full monthly-chart h-72 lg:h-[calc(100%-40px)]">
                {allCompletedZero && allInCompletedZero ? <div className="emptyMessageShow h-72 lg:full ">No Records Found</div> : <Bar options={options} data={data} plugins={[ChartDataLabels]} />}
            </div>
        </div>
    );
};

export default MonthlyOverview;
