export const API = {
    Login: {
        login: '/user/login',
        forgotPassword: '/user/forgot-password',
    },
    Employee: {
        list: '/employee/list',
        create: '/employee/create',
        detailById: '/employee',
        update: 'employee/update',
        delete: '/employee/delete',
    },
    Additional: {
        companyList: '/get-company',
        jobTypes: '/get-job-types',
        locations: '/get-locations',
        departments: '/get-department',
        requestors: '/get-requestors',
        assignor: '/get-assignor',
    },
    Job: {
        list: '/job/list',
        employeeList: '/job/get-employees',
        create: '/job/create',
        delete: '/job/delete',
    },
    JobDetail: {
        list: '/job',
        update: '/job/update',
        addTimeSheet: '/job/time-sheet',
        updateTimeSheet: 'job/time-sheet/update',
        jobComplete: '/job/complete',
        updateInvoice: '/update-invoice',
    },
    Home: {
        jobOverView: '/dashboard/job-count',
        peopleOverView: '/dashboard/people-overview',
        chartOverView: '/dashboard/monthly-overview',
    },
    Admin: {
        list: '/admin/list',
        create: '/user/create',
        timeSpent: '/admin/time-spent',
    },
};
