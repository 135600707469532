import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import InputBox from 'src/components/Common/InputBox/InputBox';
import { PATH } from 'src/constants/path';
import { doLogin } from 'src/services/login';
import { toaster } from 'src/utils/toaster';
import { validation } from 'src/constants/general';
import { useDispatch } from 'react-redux';
import { companyDataAsync, departmentDataAsync, jobTypeDataAsync, locationDataAsync, requestorDataAsync, assignorDataAsync } from 'src/redux/reducers/getCommonApiSlice';
import { ROUTES } from 'src/constants/routes';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(false);

    const onSubmit = async (values: any, helpers: any) => {
        setBtnDisabled(true);
        const payload = {
            email: values.email,
            password: values.password,
        };
        try {
            const res = await doLogin(payload);
            if (res.status === 200) {
                navigate(PATH.HOME);
                toaster('success', res.data.message);
                localStorage.setItem('token', res.data.token);
                dispatch(companyDataAsync());
                dispatch(jobTypeDataAsync());
                dispatch(locationDataAsync());
                dispatch(departmentDataAsync());
                dispatch(requestorDataAsync());
                dispatch(assignorDataAsync());
            } else {
                setError(res.data.message);
                setBtnDisabled(false);
            }
        } catch (err: any) {
            setError(err?.response.message);
        }
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required('Please enter email').email('Please enter proper email'),
            password: Yup.string().required('Please enter password').min(6, 'Password required minimum 6 characters'),
        }),
        onSubmit: onSubmit,
    });

    return (
        <div className="flex min-h-screen bg-lightgray items-center justify-center">
            <div className="bg-white rounded-[32px] shadow-lg p-10 max-md:p-8 w-[380px] max-md:w-[350px]">
                <p className="text-2xl text-black80 font-bold text-center mb-5">Login with Email</p>
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-4">
                        <InputBox
                            name={'email'}
                            type={'email'}
                            onChange={(e: any) => {
                                formik.handleChange(e);
                                setError('');
                            }}
                            onBlur={formik.handleBlur}
                            inputId={'floating_filled'}
                            value={formik.values.email}
                            placeholder="Enter Email"
                            error={formik.errors.email && formik.touched.email ? formik.errors.email : ''}
                            maxLength={validation.email}
                            className={'border border-slate-500'}
                        />
                    </div>
                    <div className="mb-4">
                        <InputBox
                            name={'password'}
                            type={'password'}
                            onChange={(e: any) => {
                                formik.handleChange(e);
                                setError('');
                            }}
                            onBlur={formik.handleBlur}
                            inputId={'floating_filled_1'}
                            value={formik.values.password}
                            placeholder="Enter Password"
                            error={formik.errors.password && formik.touched.password ? formik.errors.password : ''}
                            maxLength={validation.password}
                            className={'border border-slate-500'}
                        />
                    </div>
                    <button type="submit" className={`w-full btn-secondary btn-shadow ${btnDisabled ? 'opacity-40' : ''}`} disabled={btnDisabled}>
                        submit
                    </button>
                    {error && <p className="text-red-600 mt-2 text-sm text-center">{error}</p>}
                    <Link to={ROUTES.FORGOTPASSWORD}>
                        <p className="mt-2 text-md text-center hover:underline hover:text-gray-600">Forgot Password?</p>
                    </Link>
                </form>
            </div>
        </div>
    );
};

export default Login;
